import React from "react"
import { Link } from "gatsby"
import { Grid, Typography, Button, Card,
         CardMedia, CardContent} from "@material-ui/core"
import moment from "moment"

const PortfolioItem = props => {
  const portfolioItem = props.portfolioItem
  
  let tech = portfolioItem.metadata.item_tags.split(",")
  tech = tech.map(item => item.toLowerCase().replace(' ', ''))
  tech = props.tags.filter(({ metadata }) => tech.includes(metadata.tag_name.toLowerCase()))
  // console.log(tech)
  tech = tech.map(item => {
    return <img height="30" style={{ padding: `5px` }} src={item.metadata.tag_image.url} alt={`${item.metadata.tag_name}-tag`} />
  })

  //console.log(portfolioItem)
  return (
    <Grid xl={6} lg={6} md={10} sm={10} xs={12} spacing={1}>
      <Card className={'MuiProjectCard--01'}>
      <Grid container>
        <Grid item xs={7}>
          <CardMedia
            component={'img'}
            className={'MuiCardMedia-root'}
            src={
              portfolioItem.metadata.item_cover.url
            }
          />
        </Grid>
        <Grid item xs={5}>
            {tech}
        </Grid>
      </Grid>
        <CardContent className={'MuiCardContent-root'}>
          <Typography
            className={'MuiTypography--heading'}
            variant={'h6'}
            gutterBottom
          >
            {portfolioItem.title}
          </Typography>
          <Typography className={'MuiTypography--date'} variant={'overline'}>
            {moment(portfolioItem.metadata.item_start).format("MMM 'YY")} - {moment(portfolioItem.metadata.item_end).format("MMM 'YY")} • {portfolioItem.metadata.item_duration}
          </Typography>
          <Typography className={'MuiTypography--subheading'}>
            {portfolioItem.metadata.item_tagline}
          </Typography>
          <Link to={`portfolio/${portfolioItem.slug}`}><Button className={'MuiButton--readMore'}>Read More</Button></Link>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default PortfolioItem