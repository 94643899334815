import React from "react"


import Layout from "../components/layout"
import Portfolio from "../components/Portfolio/portfolioHolder"
import SEO from "../components/seo"

class PortfolioPage extends React.Component {
  render() {
    return (
      <div>
        <SEO title="Portfolio" lang="en"
              description="View my projects"
              meta={[`Zain`, `Khalid`, `Zain Khalid`, `Software`, `Software Developer`, `Developer`,
              `Full Stack`, `Full`, `Full Stack Developer`, `Android`, `Android Developer`, `Portfolio`]}
          />
        <Layout>
          <Portfolio />
        </Layout>
      </div>
    )
  }
}

export default PortfolioPage
