import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Grid, Typography } from "@material-ui/core"

import PortfolioItem from "./portfolioPreview"
import Tag from "../Tag/tag"

const Portfolio = () => {
  const data = useStaticQuery(graphql`
    query PortfolioQuery {
      allCosmicjsTags {
        nodes {
          metadata {
            tag_name
            tag_image {
              id
              url
            }
          }
        }
      }
      allCosmicjsPortfolioItems(sort: {fields: metadata___item_start, order: DESC}) {
        nodes {
          id
          metadata {
            item_content
            item_cover {
              url
            }
            item_duration
            item_end
            item_roles
            item_start
            item_tagline
            item_tags
          }
          title
          slug
        }
      }
    }
  `)
  const portfolio = data.allCosmicjsPortfolioItems.nodes
  const tags = data.allCosmicjsTags.nodes

  //console.log(portfolio)
  const renderPortfolioList = () => {
    return portfolio.map(portfolioItem => <PortfolioItem key={portfolioItem.id} portfolioItem={portfolioItem} tags={tags} />)
  }

  return (
    <Grid container justify="flex-start" alignItems="flex-start" direction="column">
      <Grid container justify="space-evenly" alignItems="center" spacing={2} direction="column" style={{ marginBottom: `2.0rem` }}>
        <Grid item xs><Typography variant="h5" style={{ marginBottom: `1.0rem`, marginTop: `1.0rem` }}>Portfolio</Typography></Grid>
        <Grid item xs><Tag /></Grid>
      </Grid>
      <Grid container xs={12}>
        {renderPortfolioList()}
      </Grid>
    </Grid>
  )
}

export default Portfolio